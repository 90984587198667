exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-appendix-tsx": () => import("./../../../src/pages/appendix.tsx" /* webpackChunkName: "component---src-pages-appendix-tsx" */),
  "component---src-pages-auth-reset-password-tsx": () => import("./../../../src/pages/auth/reset_password.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-tsx" */),
  "component---src-pages-auth-signin-tsx": () => import("./../../../src/pages/auth/signin.tsx" /* webpackChunkName: "component---src-pages-auth-signin-tsx" */),
  "component---src-pages-auth-signup-tsx": () => import("./../../../src/pages/auth/signup.tsx" /* webpackChunkName: "component---src-pages-auth-signup-tsx" */),
  "component---src-pages-auth-unsubscribe-tsx": () => import("./../../../src/pages/auth/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-auth-unsubscribe-tsx" */),
  "component---src-pages-auth-verify-email-tsx": () => import("./../../../src/pages/auth/verifyEmail.tsx" /* webpackChunkName: "component---src-pages-auth-verify-email-tsx" */),
  "component---src-pages-calc-tsx": () => import("./../../../src/pages/calc.tsx" /* webpackChunkName: "component---src-pages-calc-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dashboard-create-profile-tsx": () => import("./../../../src/pages/dashboard/createProfile.tsx" /* webpackChunkName: "component---src-pages-dashboard-create-profile-tsx" */),
  "component---src-pages-dashboard-edit-profile-tsx": () => import("./../../../src/pages/dashboard/editProfile.tsx" /* webpackChunkName: "component---src-pages-dashboard-edit-profile-tsx" */),
  "component---src-pages-dashboard-explanatory-statement-tsx": () => import("./../../../src/pages/dashboard/explanatoryStatement.tsx" /* webpackChunkName: "component---src-pages-dashboard-explanatory-statement-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-content-template-tsx": () => import("./../../../src/templates/ContentTemplate.tsx" /* webpackChunkName: "component---src-templates-content-template-tsx" */),
  "component---src-templates-survey-template-tsx": () => import("./../../../src/templates/SurveyTemplate.tsx" /* webpackChunkName: "component---src-templates-survey-template-tsx" */)
}


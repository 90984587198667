import { createTheme } from '@mui/material';
import { cyan, red } from '@mui/material/colors';
import { blueGrey } from '@mui/material/colors';

export default createTheme({
  palette: {
    error: {
      main: red.A400,
    },
    background: {
      default: blueGrey[50],
    },
    info: {
      main: cyan[500],
    },
  },
});

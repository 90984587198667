import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { authenticated } from '../authSlice';

import type { Store } from 'redux';

export const loadAuth = (store: Store) => {
  return onAuthStateChanged(getAuth(), (userInfo) => {
    if (userInfo) {
      store.dispatch(authenticated(userInfo));
    } else {
      store.dispatch(authenticated(null));
    }
  });
};

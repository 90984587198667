import update from 'immutability-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { authenticated } from './authSlice';
import { startTopics, createTopicPlan } from './thunks/topicThunks';
import { fetchProfile, createProfile, updateProfile } from './thunks/userThunks';
import { updateSurvey, startSurvey } from './thunks/surveyThunks';
import { updatePhotoURL } from './thunks/authThunks';
import type { state } from '../types';

export type ProfileData = {
  user: state.User;
  topics: Record<string, state.Topic>;
  surveys: Record<string, state.Survey>;
};

export type ProfileState = {
  loaded: boolean;
  data: ProfileData | null;
  error?: unknown;
};

export const initialState: ProfileState = {
  loaded: false,
  data: null,
};

const { reducer, actions } = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateTopic(state, action: PayloadAction<{ topicKey: string; data: Partial<state.Topic> }>) {
      const { topicKey, data } = action.payload;
      return update(state, {
        data: { topics: { [topicKey]: { $merge: data } } },
      });
    },

    updateTopicEntry(
      state,
      action: PayloadAction<{ topicKey: string; entryKey: string; data: Partial<state.TopicEntry> }>,
    ) {
      const { topicKey, entryKey, data } = action.payload;
      const entries = state.data!.topics[topicKey].entries;
      entries[entryKey] = { ...entries[entryKey], ...data };

      // return update(state, {
      //   data: { topics: { [topicKey]: { entries: { [entryKey]: { $merge: data } } } } },
      // });
    },

    markLocalCompleted(state, action: PayloadAction<{ scope: string }>) {
      const { scope } = action.payload;
      return update(state, {
        data: {
          user: {
            $merge: { [`${scope}CompletedAt`]: Timestamp.now() },
          },
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticated, (state, action) => {
        return action.payload === null ? update(state, { $set: initialState }) : state;
      })
      .addCase(startSurvey.fulfilled, (state, action) => {
        const { id, currentStage, data } = action.payload;
        return update(state, {
          data: {
            surveys: { [id]: { $set: data } },
            ...(currentStage ? { user: { currentStage: { $set: currentStage } } } : {}),
          },
        });
      })
      .addCase(updateSurvey.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        return update(state, {
          data: {
            surveys: {
              [id]: { $merge: data },
            },
          },
        });
      })

      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loaded = true;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loaded = false;
      })
      .addCase(createProfile.fulfilled, (state, action) => {
        const user = action.payload;
        state.data = {
          user,
          topics: {},
          surveys: {},
        };
        state.loaded = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        return update(state, {
          data: {
            user: {
              $merge: action.payload,
            },
          },
        });
      })
      .addCase(updatePhotoURL.fulfilled, (state, action) => {
        return update(state, {
          data: {
            user: {
              $merge: action.payload,
            },
          },
        });
      })
      .addCase(startTopics.fulfilled, (state, action) => {
        return update(state, { data: { user: { currentStage: { $set: 'topic' } } } });
      })
      .addCase(createTopicPlan.fulfilled, (state, action) => {
        const { topics, user } = action.payload;
        return update(state, { data: { topics: { $set: topics }, user: { $merge: user } } });
      });
  },
});

export { reducer, actions };

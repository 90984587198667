import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  UserInfo,
  updatePassword,
  updateProfile,
  sendEmailVerification,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { load } from 'recaptcha-v3';
import { getApp } from 'firebase/app';
import {getFunctions, httpsCallable} from "firebase/functions";

export type Credential = {
  email: string;
  password: string;
  remember?: boolean;
};

export const signin = createAsyncThunk('auth/signin', async (credential: Credential): Promise<void> => {
  const { email, password } = credential;
  await signInWithEmailAndPassword(getAuth(), email, password);
});

export const signup = createAsyncThunk(
  'auth/signup',
  async (payload: { email: string; password: string }): Promise<void> => {
    const { email, password } = payload;
    const auth = getAuth();
    const analytics = getAnalytics();
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(user);
    logEvent(analytics, 'signup');
  },
);

export const verifyEmail = createAsyncThunk('auth/verifyEmail', async (): Promise<void> => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    await sendEmailVerification(user);
  }
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (email: string): Promise<void> => {
  const auth = getAuth();
  const analytics = getAnalytics();
  await sendPasswordResetEmail(auth, email);
  logEvent(analytics, 'reset_password');
});

export const signout = createAsyncThunk('auth/signout', async (): Promise<void> => {
  const auth = getAuth();
  await signOut(auth);
});

export const changePassword = createAsyncThunk('auth/changePassword', async (newPassword: string): Promise<void> => {
  const auth = getAuth();
  await updatePassword(auth.currentUser!, newPassword);
});

export const updatePhotoURL = createAsyncThunk(
  'auth/updatePhoto',
  async (photoURL: string): Promise<Partial<UserInfo>> => {
    const auth = getAuth();
    await updateProfile(auth.currentUser!, { photoURL });
    return { photoURL };
  },
);

export const unsubscribe = createAsyncThunk('auth/unsubscribe', async ({ email }: { email: string }) => {
    const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY;
    if (!recaptchaSiteKey) {
        return;
    }
    const recaptcha = await load(recaptchaSiteKey);
    const token = await recaptcha.execute('unsubscribe');

    const functions = getFunctions(getApp());

    await httpsCallable(
        functions,
        'unsubscribeFromEmails',
    )({
        email,
        recaptchaToken: token,
    });
});
